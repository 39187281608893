import MediaService from '@/services/api/MediaService'

export default class UploadAdapter {
  loader: any
  editor: any
  mediaService: MediaService

  constructor(loader, editor) {
    this.loader = loader
    this.editor = editor
    this.mediaService = MediaService.build()
  }

  bytesToMegaBytes(bytes) { 
    return bytes / (1024*1024); 
  }

  upload() {
    return new Promise((resolve, reject) => {
      
      const data = new FormData()

      this.loader.file.then(
        file =>
          new Promise((resolveFile, rejectFile) => {
            data.append('file', file)
            
            if (this.bytesToMegaBytes(file.size) > 3) {
                reject('Por favor selecione uma imagem com até 3 MB')
            }
            
            this.mediaService
              .optimizeImage(data)
              .then(
                response => {
                  resolve({
                    default: response.base64
                  })
                },
                error => {
                  reject('Não foi possível incluir esta imagem.')
                }
              )
          })
      )
      
    })
  }

  abort() {}
}
