<template>
  <div>

    <div v-html-safe="editor_data"
      id="editorData"
      class="editor_data editor-content ck-content"
      @click="turnEditorVisible"
      v-tooltip.auto="{
        content: 'Editar conteúdo',
        delay: { show: 1000, hide: 100 },
      }"
      v-show="readOnly && !isEmpty(editor_data) && !itemFocusSelected"
    >
    </div>

    <div
      class="editor_data"
      @click="turnEditorVisible"
      v-tooltip.auto="{
        content: 'Editar conteúdo',
        delay: { show: 1000, hide: 100 },
      }"
      v-if="readOnly && isEmpty(editor_data) && !itemFocusSelected"
    >
      <span>{{placeholder}}</span>
    </div>

    <ckeditor
      ref="editor"
      :key="editorKey"
      :editor="editor"
      v-model="editor_data"
      :config="editorConfig"
      @input="dataChanged"
      @blur="onBlur"
      @destroy="onDestroy"
      @ready="onReady"
      v-if="editor && !readOnly || itemFocusSelected"
    >
    </ckeditor>
  </div>
</template>

<script>
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
// import BalloonEditor from '@ckeditor/ckeditor5-editor-balloon/src/ballooneditor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Link from '@ckeditor/ckeditor5-link/src/link'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import List from '@ckeditor/ckeditor5-list/src/list'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import ptBR from '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'
// import MathType from '@wiris/mathtype-ckeditor5'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import Base64UploadAdapter from '@ckeditor/ckeditor5-image/node_modules/@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import UploadAdapter from '../../../../util/ckeditor/UploadAdapter'
import CustomFigureAttributes from '../../../../util/ckeditor/CustomFigureAttributes'
// import UploadUrlAdapter from '../../../../util/ckeditor/UploadUrlAdapter'

export default {
  components: {},
  props: {
    modelId: {
      type: Number,
      default: null,
    },
    editor_data: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('insira-o-texto-aqui')
      },
    },
    editorType: {
      type: String,
      default: 'classic',
    },
    autoOpen: {
      type: Boolean,
      default: false
    },
    dontClose: {
      type: Boolean,
      default: false
    },
    imageType: {
      type: String,
      default: 'base64'
    }
  },
  data: function () {

    const items = [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'fontFamily',
      'fontSize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'alignment',
      'link',
      '|',
      'outdent',
      'indent',
      'bulletedList',
      'numberedList',
      '|',
      'blockQuote',
      'insertTable',
      'mediaEmbed',
      '|',
      'imageUpload',
      '|',
      'horizontalLine',
      '|',
      'undo',
      'redo',
    ]

    const plugins = [
      Essentials,
      Bold,
      Italic,
      Underline,
      BlockQuote,
      Heading,
      Image,
      ImageCaption,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      ImageResize,
      HorizontalLine,
      Link,
      Indent,
      IndentBlock,
      Font,
      List,
      MediaEmbed,
      Table,
      TableToolbar,
      Alignment,
      PasteFromOffice
    ]

    const resizeOptions = [
      {
        name: 'imageResize:original',
        value: null,
        icon: 'original',
      },
      {
        name: 'imageResize:10',
        label: '10%',
        value: '10',
      },
      {
        name: 'imageResize:20',
        label: '20%',
        value: '20',
      },
      {
        name: 'imageResize:30',
        label: '30%',
        value: '30',
      },
      {
        name: 'imageResize:40',
        label: '40%',
        value: '40',
      },
      {
        name: 'imageResize:50',
        label: '50%',
        value: '50',
      },
      {
        name: 'imageResize:60',
        label: '60%',
        value: '60',
      },
      {
        name: 'imageResize:70',
        label: '70%',
        value: '70',
      },
      {
        name: 'imageResize:80',
        label: '80%',
        value: '80',
      },
      {
        name: 'imageResize:90',
        label: '90%',
        value: '90',
      },
      {
        name: 'imageResize:100',
        label: '100%',
        value: '100',
      }
    ]

    // if (eval(process.env.VUE_APP_MATH_TYPE_ENABLED)) {
    //   items.push('MathType')
    //   items.push('ChemType')
    //   items.push('|')
    //   plugins.push(MathType)
    // }

    return {
      editorKey: 0,
      readOnly: true,
      itemFocusSelected: false,
      editor: null,
      editorConfig: {
        parentId: null,
        extraPlugins: this.customPluginAvaiable(),
        // language: ptBR,
        plugins: plugins,
        mediaEmbed: {
          previewsInData: true
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3',
            },
          ],
        },
        // Configure the available image resize options.
        resizeOptions: [
          {
            name: 'imageResize:original',
            label: 'Original',
            value: null,
          },
          {
            name: 'imageResize:25',
            label: '25%',
            value: '25',
          },
          {
            name: 'imageResize:50',
            label: '50%',
            value: '50',
          },
          {
            name: 'imageResize:75',
            label: '75%',
            value: '75',
          },
        ],
        toolbar: {
          items: items,
          shouldNotGroupWhenFull: true
        },
        image: {
          // Configure the available styles.
          styles: ['alignLeft', 'alignCenter', 'alignRight'],

          // Configure the available image resize options.
          resizeOptions: [
            {
              name: 'imageResize:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'imageResize:25',
              value: '25',
              icon: 'small',
            },
            {
              name: 'imageResize:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'imageResize:75',
              value: '75',
              icon: 'large',
            },
          ],

          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageResize',
            '|',
            'imageTextAlternative',
          ],
        },
      },
      blurTimeout: null
    }
  },
  watch: {
    modelId: function (val) {
      this.editorConfig.parentId = val
      this.editorKey++
    },
    readOnly: function (val){
      this.$emit('complex-editor-is-open', val) //If editor is edit (format text)
      console.log('ComplexEditor readOnly: '+val)
    }
  },
  methods: {
    customUploadAdapterPlugin(editor) {

      // Allow <div> elements in the model to have all attributes.
      // editor.model.schema.addAttributeCheck( context => {
      //     return true;
      // } )

      // editor.conversion.for( 'downcast' ).add( dispatcher => {
      //   dispatcher.on( 'insert:image', ( evt, data, conversionApi ) => {

      //     const modelElement = data.item;
      //     const viewWriter = conversionApi.writer;
      //     const viewFigure = editor.editing.mapper.toViewElement( modelElement );

      //     if ( !viewFigure || !viewWriter) {
      //       return;
      //     }

      //     const img = viewFigure.getChild(0);

      //     viewWriter.addAttribute('width', 100, img);

      //     // console.log('data', data)
      //     // const modelElement = data.item;
      //     // const writer = conversionApi.writer;
      //     // const viewFigure = editor.editing.mapper.toViewElement( modelElement );

      //     // if ( !viewFigure || !writer) {
      //     //   return;
      //     // }

      //     // // const viewElement = findViewChild( viewFigure, 'img', conversionApi )
      //     // const viewElement = [ ...viewFigure.getChildren() ].find( element => element.name === 'img' );

      //     // const height = modelElement.getAttribute( 'height' ) || 0;
      //     // // if ( height > 0 ) {
      //     //   writer.setAttribute( 'width', 300, viewElement );
      //     //   // return;
      //     // // }
      //     // const img = conversionApi.view.domConverter.viewToDom( viewElement );
      //     // img is undefined
      //   } );
      // }, { priority: 'low' } );

      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(
          loader,
          editor
        )
      }
    },
    dataChanged() {
      // Ooooh mysterious code, this event isnt being used ANYWHERE,
      // But remove it and ComplexEditor will stop updating correctly.
      this.$emit('update:editor_data', this.editor_data)
      // We emit this one cause we cant use @update:editor_data due to ":"
      this.$emit('data-changed')
    },
    onBlur(e) {
      this.blurTimeout = setTimeout(() => {
        if (this.readOnly && this.itemFocusSelected && !this.dontClose) {
          this.itemFocusSelected = false
        }

        this.parseFormula('editorData')

        if (!this.dontClose && !this.isTableParent(e)) {
          this.readOnly = true
        }

        if (!this.isTableParent(e)) {
          this.$emit('blur')
        }

      }, 500) // Delay in milliseconds to allow the editor to save its content internally before we "lock it"
    },
    onDestroy() {
      this.$emit('destroy')
    },
    onReady() {
      const editor = this.$refs.editor.$_instance
      const view = !this.isEmpty(this.$refs.editor) && !this.isEmpty(editor) ? editor.editing.view : null
      if (view) {
        view.focus()
      }

      const toolbarItems = editor.ui.view.toolbar ? editor.ui.view.toolbar.items._items : []
      toolbarItems.forEach(item => {
        item.element.addEventListener('click', () => {
          this.itemFocusSelected = true
        })
      })

      // const commandMathType = editor.commands.get('link')
      // commandMathType.on('execute', () => {
      //   this.itemFocusSelected = true
      // })
    },
    turnEditorVisible() {
      this.readOnly = false
    },
    customPluginAvaiable() {
      let plugins = []
      if (this.imageType === 'base64') {
        plugins.push(this.customUploadAdapterPlugin)
      }
      return plugins
    }
  },
  computed: {

  },
  beforeMount() {
    this.editorConfig.parentId = this.modelId
    if (this.editorType === 'classic') {
      import('@ckeditor/ckeditor5-editor-classic/src/classiceditor').then(({ default: ClassicEditor }) => {
        this.editor = ClassicEditor
      })
    } else {
      import('@ckeditor/ckeditor5-editor-balloon/src/ballooneditor').then(({ default: BalloonEditor }) => {
        this.editor = BalloonEditor
      })
    }
  },
  created() {},
  mounted() {
    if (this.autoOpen) {
      this.turnEditorVisible()
    }
  },
  beforeDestroy() {
    if (!this.isEmpty(this.$refs.editor) && !this.isEmpty(this.$refs.editor.$_instance)) {
      this.$refs.editor.$_instance.destroy()
    }

    this.destroyChilds(this)
  }
}
</script>

<style>
.editor_data {
  border: 1px solid #eee !important;
  border-radius: 5px;
  -webkit-transition: border-color 0.5s ease-out;
  padding: 10px;
  cursor: text;
}
</style>
